.next-steps .fa-link {
  margin-right: 5px;
}

.active-link {
  border-bottom: 2px solid green;
}

/* Custom CSS to make the active link underline green */
.green-active-link {
  border-bottom: 2px solid green;
  color: green; /* You can also set the text color to green if needed */
}


/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}
