/* Hero.css */
.spin-logo {
  animation: spin 5s linear infinite; /* Increase the duration for a slower spin */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
