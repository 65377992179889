body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Nunito', sans-serif;
}
.active-link {
  border-bottom: 2px solid green;
}

/* Custom CSS to make the active link underline green */
.green-active-link {
  border-bottom: 2px solid green;
  color: green; /* You can also set the text color to green if needed */
}


