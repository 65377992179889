/* HologramSwitch.css */

/* HologramSwitch.css - Adjusted for internal text display */

.slider-container {
  position: relative;
  width: fit-content;
  display: inline-block;
}

.custom-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 50px;
  height: 25px;
  background-color: #cccccc;
  border-radius: 25px;
  outline: 2px solid #cccccc;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
}

.custom-slider.on {
  background-color: #4CAF50;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.status-offline, .status-online, .status-checking {
  transition: opacity 0.5s ease;
}


.custom-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}



/* Add CSS styles for spacing between text and dot */
.status-offline {
  display: inline-block;
  margin-right: 0px; /* Adjust the margin to your desired spacing */
}

.status-online {
  display: inline-block;
  margin-right: 0px; /* Adjust the margin to your desired spacing */
}


/* Style the label for centering and adding padding */

/* FOR NEW STATUS ENDPOINT*/
.status-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.online-dot {
  background-color: #4CAF50; /* Green */
}

.offline-dot {
  background-color: #9E9E9E; /* Gray */
}


/* Position text within the slider */
.slider-text {
  display: block;
  text-align: center;
  margin-top: 5px; /* Adjust as needed */
  font-size: 11px; /* Adjust as needed */
}

/* Media query for screens with a maximum width of 768px */
@media (max-width: 768px) {
  /* Modify styles for smaller screens here */
  .custom-slider {
    width: 90%; /* Adjust the width for smaller screens */
  }

  /* Add additional responsive styles as needed */
}

/* Media query for screens with a maximum width of 480px */
@media (max-width: 480px) {
  /* Modify styles for even smaller screens here */
  .custom-slider {
    width: 90%; /* Adjust the width for even smaller screens */
  }

  /* Add additional responsive styles as needed */
}

/*Other colors*/
.online-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  margin-right: 5px;
}

.unknown-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: yellowgreen;
  margin-right: 5px;
}

.red-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
}

.gray-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  margin-right: 5px;
}

.orange-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: orange;
  margin-right: 5px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-text {
  animation: loading-text 1.5s linear infinite;
  padding: 50px; /* adjust as needed */
}

@keyframes loading-text {
  0% { color: black; }
  50% { color: grey; }
  100% { color: black; }
}

.toggle-button {
  display: flex; /* Flex layout to position labels side by side */
  width: 100px; /* Set a fixed width */
  height: 60px; /* Set a fixed height for better control */
  justify-content: space-around; /* Space around the labels */
  cursor: pointer;
  border: none; /* Remove border for a cleaner look */
  border-radius: 0px; /* Highly rounded borders */
  overflow: hidden; /* Ensures inner elements don't spill over border */
  background-color: #fff; /* Default background, though it shouldn't be visible */
  transition: background-color 0.3s; /* Smooth transition for color changes */
}

.button-label {
  flex: 1; /* Each label takes half the space */
  text-align: center;
  padding: 5px 0; /* Sufficient padding for a better touch area */
  transition: background-color 0.3s; /* Smooth transition for background color change */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%; /* Ensure each label fills half the button */
  /* Apply border-radius to the first and last child */
  border-radius: 10px 0 0 10px; /* First child rounded on the left */
  border: #8f8f8f solid 1.2px; /* Add border to each label */
}

.button-label:last-child {
  border-radius: 0 10px 10px 0; /* Last child rounded on the right */
}

.toggle-button.on .on { /* Styling when ON is active */
  background-color: #28a745; /* Green color for ON */
  color: white;
  border: #28a745 solid 1px;
  border-color: #28a745;
}

.toggle-button.off .off { /* Styling when OFF is active */
  background-color: #8f8f8f; /* Dark gray color for OFF */
  color: white;
  border: #8f8f8f solid 1px;
  border-color: #8f8f8f;
}

.switch-container {
  margin: 0px;
  margin-top: 25px;
  width: 100px; /* Adjusted width to match button */
}
