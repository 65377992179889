/* For mobile devices */
@media (max-width: 768px) {
    .table-container {
        margin: 50px;
        width: 90%;

        /* Add other mobile-specific styles here */
      }
  
    .card-container {
      width: 90%;
      /* Add other mobile-specific styles here */
    }
  
    .switch-container {
      /* Add mobile-specific styles for switches */
      width: 90%;
    }

    .responsive-image {
        max-width: 100%;
        height: auto;
        display: block; /* Remove any extra spacing below the image */
        margin: 0 auto; /* Center the image horizontally */
      }
  }
  
  /* For iPads or similar-sized tablets */
  @media (min-width: 769px) and (max-width: 1024px) {
    .table-container {
      margin: 20px;
      /* Add other tablet-specific styles here */
    }
  
    .card-container {
      width: 80%;
      /* Add other tablet-specific styles here */
    }
  
    .switch-container {
      /* Add tablet-specific styles for switches */
      width: 80%;
    }
    .responsive-image {
        max-width: 100%;
        height: auto;
        display: block; /* Remove any extra spacing below the image */
        margin: 0 auto; /* Center the image horizontally */
      }
  }
  